<template>
  <div class="tiptap-toolbar" :data-shared="shared">
    <div class="flex button-group" :class="{ 'gap-1': currentEditor }">
      <div v-if="currentEditor" class="flex gap-1">
        <button v-for="item in toolbarItems" :key="item.name" class="flex" @click="item.action(currentEditor)" :class="{ 'is-active': item.isActive(currentEditor) }">
          <component v-if="item.component" :is="item.component" class="h-4 w-4" />
          <span v-else v-html="item.icon"></span>
        </button>
      </div>
      <div v-else></div>
      <button class="flex" v-if="shared" @click=openLayoutClick>
        <RectangleGroupIcon class="h-5 w-5" />
        <span class="text-sm ml-1">Layout</span>
      </button>
    </div>

    <div v-if="shared" class="text-sm text-gray-600 ml-4 flex items-center h-6">
      <SaveStatus />
    </div>
  </div>
</template>

<script>
import { inject } from 'vue'
import SaveStatus from './SaveStatus.vue'
import { RectangleGroupIcon, BoldIcon, ItalicIcon, StrikethroughIcon, ListBulletIcon, NumberedListIcon, H1Icon, H2Icon, H3Icon, ArrowUturnLeftIcon, ArrowUturnRightIcon } from '@heroicons/vue/24/outline'
import { TOOLBAR_ITEMS } from '@/helpers/constants/toolbar_items'
import { useSidePanelHelpers } from '../../composables/useSidePanelHelpers'

export default {
  name: 'Toolbar',
  components: {
    SaveStatus,
    RectangleGroupIcon,
    BoldIcon,
    ItalicIcon,
    StrikethroughIcon,
    ListBulletIcon,
    NumberedListIcon,
    H1Icon,
    H2Icon,
    H3Icon,
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon
  },
  props: {
    shared: {
      type: Boolean,
      default: true
    },
    editor: {
      type: Object,
      default: null
    },
    excludedItems: {
      type: Array,
      default: []
    }
  },
  setup(props) {
    const currentEditor = props.editor || inject('currentEditor')
    const { replaceInStack } = useSidePanelHelpers()
    const parsedLayouts = inject('parsedLayouts')
    const toolbarItems = TOOLBAR_ITEMS.filter(item => !props.excludedItems.includes(item.name))

    const openLayoutClick = () => {
      replaceInStack({
        content: {
          title: 'Choose a layout',
          component: 'LayoutForm',
          props: { layouts: parsedLayouts.value }
        }
      })
    }

    return {
      currentEditor,
      openLayoutClick,
      toolbarItems
    }
  }
}
</script>

<style scoped>
  .button-group button {
    padding: 2px 6px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #f0f9ff;
      cursor: pointer;
    }

    &.is-active {
      font-weight: bold;
      background-color: #f0f9ff;
      color: #4338ca;
    }
  }

  .tiptap-toolbar[data-shared="true"] {
    .button-group button {
      padding: 6px 12px;
      font-size: 16px;
    }
  }
</style>
