import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['sticky']
  static values = {
    offset: {
      type: Number,
      default: 0
    }
  }

  connect() {
    this.offsetTop = this.element.offsetTop
    window.addEventListener('scroll', this.onScroll.bind(this))
  }

  onScroll() {
    if (window.scrollY > (this.offsetTop + this.offsetValue)) {
      this.element.classList.add(...this.stickyClasses)
    } else {
      this.element.classList.remove(...this.stickyClasses)
    }
  }
}